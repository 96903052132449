.loading-image-container {
  background-color: #FFFFFF; /* Set background color to white */
  width: 100%; /* Adjust width as necessary */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  border-radius: 8px; /* Optional: for rounded corners */
}

.loading-bar-background {
    width: 60%; 
    display: flex;
    /* flex-direction: column;*/
    /* justify-content: center; */
    align-items: center; /* Center children horizontally */
    background-color: #e0e0e0;
    border-radius: 2px;
    margin-top: 10px; /* Adjust based on your layout */
}
  
.loading-bar {
    height: 10px; /* Height of the loading bar */
    width: 0%; /* Initial width of the loading bar */
    /* background-color: #4caf50;  Color of the loading bar */
    border-radius: 2px;
    transition: width 2s ease-in-out; /* Smooth transition for the loading effect */
    animation: load 20s infinite; /* Adjust '3s' to control the speed */
}


.loading-image {
  width: 60%; /* Adjust width as necessary */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure entire image fits within the space */
  border-radius: 8px; /* Optional: rounds the corners */
  background-color: #FFFFFF; /* Pure white for content area */
}
  
/* Keyframes for the loading animation */
@keyframes load {
    0% {
      width: 0%;
    }
    50% {
      width: 50%;
    }
    100% {
      width: 100%;
    }
}