/* General App Styles */
.Easter-App {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #E6E6FA; /* Soft pink background */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;  
  justify-content: center;
  font-size: 16px;
  color: #333;
  padding: 20px; /* Add padding to ensure content doesn't touch the edges */
  box-sizing: border-box; /* Ensures padding doesn't affect the overall width */
}

.Easter-App-header {
  background-color: #FFFFFF; /* Pure white for content area */
  border-radius: 20px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.05); /* Lighter shadow for softer look */
  margin: 1px auto; /* Ensure it's centered and has space on smaller screens */
  padding: 20px; /* Adjust padding as needed */
  width: 95%; /* Allow it to adapt based on the screen size */
  max-width: 800px; /* Keep the maximum width for larger screens */
}

.Easter-App .buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Adjust based on your spacing preference */
  margin-bottom: 1px; /* Extra space before the form for better layout */
  margin-left: 30px;
  margin-right: 30px;
}

/* Theme Buttons - centered */
.Easter-App .theme-buttons {
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  gap: 10px;
}

.Easter-App .theme-buttons button {
  background-color: #BAABD4; /* Soft girly pink */
  color: white;
  border: none;
  padding: 10px 25px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 1rem;  
}

.Easter-App .theme-buttons button:hover {
  background-color: #9C94C9; /* Slightly darker pink on hover */
  transform: translateY(-3px);
}

@keyframes flash {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.3; }
}

.Easter-App .lucky-button {
  animation: flash 2s infinite; /* Adjust timing as needed */
  padding: 10px 25px;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 1rem;
  font-weight: bold;
  border: 2px solid #BFA2DB;
  background-color: #957DAD;
  color: #FFFFFF;
  margin: 10px;
}

.Easter-App .lucky-button:hover {
  background-color: #D6CDEA;
  color: #5D3B76;
  transform: translateY(-3px);
}

.Easter-App .lucky-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Form Styles */
.Easter-App form {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.Easter-App form input[type="text"] {
  padding: 10px 15px;
  border-radius: 20px;
  border: 2px solid #ddd;
  width: 250px;
  font-size: 1rem;
}

.Easter-App form button {
  padding: 10px 20px;
  background-color: #BAABD4; /* Matching button color */
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 1rem;
}

.Easter-App form button:hover {
  background-color: #9C94C9;
  transform: translateY(-3px);
}

.Easter-App form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Loader Styles */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Image Display */
.generated-image {
  max-width: 80%;
  border-radius: 10px;
  margin-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .Easter-App-header {
    border-radius: 10px; /* Less pronounced border-radius on smaller screens */
    padding: 5px; /* Smaller padding */
  }
  .Easter-App .theme-buttons button, .lucky-button {
    padding: 8px 20px; /* Smaller buttons on smaller screens */
  }
  .Easter-App form input[type="text"], form button {
    width: auto; /* Allow form elements to fill available space */
    max-width: 250px; /* Prevent them from becoming too wide */
    font-size: 0.9rem; /* Slightly smaller font size */
  }
}

@media (max-width: 480px) {
  .Easter-App {
    padding: 10px; /* Reduce the padding on smaller screens */
  }
  .Easter-App-header {
    border-radius: 10px; /* Adjust if needed */
    padding: 1px; /* You can adjust this if needed for the content inside the white box */
  }

  .Easter-App .theme-buttons button, .lucky-button {
    margin-bottom: 10px; /* Adjust spacing between rows as needed */
    padding: 8px 9px; /* Adjust padding to ensure text fits */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    font-size: 1rem
  }
}
.social-links {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust the space between icons */
  margin-bottom: 10px;
}

.social-links a {
  color: #000; /* Icon color */
  text-decoration: none; /* Removes underline from links */
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the icon and text */
}
  
.social-links i {
  transition: color 0.3s ease; /* Smooth color transition */
}

.social-links a:hover i {
  color: #007bff; /* Change color on hover */
}

.status-message {
  font-size: 1.25rem; /* Bigger font size */
  color: #333; /* Dark gray for text, assuming this matches your app */
  margin: 10px 0; /* Adds some space above and below the message */
  padding: 10px; /* Adds some padding for better readability */
  border-radius: 8px; /* Soft rounded corners for a subtle, modern look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  width: auto; /* Adjust width as needed */
  max-width: 95%; /* Prevents the message from becoming too wide */
  text-align: center; /* Centers the text within the div */
}

