.social-links {
    display: flex;
    align-items: center;
    justify-content: center; /* Adjust based on your layout preference */
    flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.icon-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin: 1px; /* Adjust spacing around each icon */
}

/* Specific hover effect for the container around IoApps icon */
.icon-hover-container:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Light circle background on hover */
    border-radius: 50%; /* Ensures the background is circular */
    transition: background-color 0.3s; /* Smooth transition */
}

.social-text {
    margin-left: 1px; /* Space between icon and text */
}

.description-box {
    display: none; /* Initially hidden */
    position: absolute;
    top: 100%; /* Position below the icon */
    left: 50%; /* Centered horizontally */
    transform: translateX(-50%);
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 14px;
    white-space: nowrap; /* Keep the text in a single line */
    margin-top: 10px; /* Space between the icon and the box */
}

/* Responsive styles */
@media (max-width: 480px) {
    /* Hide social text on mobile */
    .social-text {
        display: none;
    }
    .icon-container:hover .description-box {
        display: block; /* Show on hover */
    }
}


