.gallery-container {
    text-align: center;
    margin: 40px 20px 20px; /* Increased top margin for more space above the title */
}

.gallery-title {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 5px; /* Adjust if more space needed */
    color: #4a90e2; /* Soft blue for the title */
}

.gallery-container h4 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    color: #4a90e2;
    margin-bottom: 1rem;
    margin-top: 1px;
}

.app-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.app-card {
    width: 250px; /* Set a fixed width for all cards */
    background-color: #ffffff; /* White background for the cards */
    border: none; /* Removing the border for a cleaner look */
    border-radius: 8px;
    overflow: hidden;
    text-decoration: none;
    color: #333333; /* Dark gray text */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Shadow effect */
    display: flex;
    flex-direction: column;
}

.app-card:hover {
    transform: translateY(-5px); /* Slight raise effect on hover */
    box-shadow: 0 6px 12px rgba(0,0,0,0.2); /* Enhanced shadow on hover */
}

.app-card img {
    width: 100%; /* Make image take up all available width */
    height: auto; /* Maintain aspect ratio of the image */
    object-fit: cover; /* Ensure images cover the area */
}

.app-card div {
    padding: 15px;
}

.app-card h3, .app-card p {
    margin: 0; /* Remove default margin for clean spacing */
    color: #666666; /* Medium gray for text */
}

@media screen and (max-width: 600px) {
    .app-card {
        width: 100%; /* Full width on smaller screens */
    }
}