/* General App Styles */
.custom-card-button {
  padding: 10px 5px;
  background-color: #FFB7C5; /* Same color as the submit button */
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 1.4rem;
  width: calc(90% / 3); /* Equal width for each button */
  margin: 5px
}

.custom-card-button:hover {
  background-color: #FF6F61;
  transform: translateY(-3px);
}

.custom-card-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.button-row {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.buddha-home-image {
  max-width: 400px;
  width: 80%; /* Ensure the image scales properly */
  height: auto; /* Maintain aspect ratio */
  border-radius: 15px;
}

.text-area {
  width: 80%; /* Take up full width of the container */
  max-width: 400px; /* Limit the maximum width of the textarea */
  resize: vertical; /* Allow vertical resizing */
  overflow-y: auto; /* Show vertical scrollbar when needed */
  min-height: 50; /* Set a minimum height */
  font-size: 20px; /* Adjust the font size */
  padding: 10px; /* Add padding for better readability */
  border-radius: 15px;
}

.Card-App {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #FF99CC; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;  
  justify-content: center;
  font-size: 20px;
  color: #FF6F61;
  padding: 20px; /* Add padding to ensure content doesn't touch the edges */
  box-sizing: border-box; /* Ensures padding doesn't affect the overall width */  
}

.Card-App-header {
  background-color: #FFFFFF; /* Pure white for content area */
  border-radius: 20px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.05); /* Lighter shadow for softer look */
  margin: 1px auto; /* Ensure it's centered and has space on smaller screens */
  padding: 20px; /* Adjust padding as needed */
  width: 95%; /* Allow it to adapt based on the screen size */
  max-width: 800px; /* Keep the maximum width for larger screens */
}

/* Form Styles */
.form-container-buddha {
  display: flex;
  flex-direction: column; /* Display form items in a column */
  align-items: center; /* Center align form items horizontally */
  gap: 10px; /* Add gap between form items */
  margin-bottom: 10px; /* Add margin at the bottom of the form container */
}

.Card-App .submit-button {
  padding: 5px 10px;
  background-color: #FFB7C5; /* Matching button color */
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 1.4rem;
  width: 80%; /* Set width of the button */
  max-width: 400px; /* Limit the maximum width of the button */
}

.Card-App .submit-button:hover {
  background-color: #FF6F61;
  transform: translateY(-3px);
}

.Card-App .submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Loader Styles */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Image Display */
.generated-image {
  max-width: 80%;
  border-radius: 10px;
  margin-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .Card-App-header {
    border-radius: 10px; /* Less pronounced border-radius on smaller screens */
    padding: 5px; /* Smaller padding */
  }
}

@media (max-width: 480px) {
  .Card-App {
    padding: 10px; /* Reduce the padding on smaller screens */
  }
  .Card-App-header {
    border-radius: 10px; /* Adjust if needed */
    padding: 1px; /* You can adjust this if needed for the content inside the white box */
  }
}
.social-links {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust the space between icons */
  margin-bottom: 10px;
}

.social-links a {
  color: #000; /* Icon color */
  text-decoration: none; /* Removes underline from links */
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the icon and text */
}
  
.social-links i {
  transition: color 0.3s ease; /* Smooth color transition */
}


