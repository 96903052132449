/* General App Styles */
.Prompt-App {
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    background-color: #A3C9FF; 
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: center;
    font-size: 16px;
    color: #333;
    padding: 20px; /* Add padding to ensure content doesn't touch the edges */
    box-sizing: border-box; /* Ensures padding doesn't affect the overall width */  
  }
  
  .Prompt-App-header {
    background-color: #FFFFFF; /* Pure white for content area */
    border-radius: 20px;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.05); /* Lighter shadow for softer look */
    margin: 1px auto; /* Ensure it's centered and has space on smaller screens */
    padding: 20px; /* Adjust padding as needed */
    width: 95%; /* Allow it to adapt based on the screen size */
    max-width: 800px; /* Keep the maximum width for larger screens */
  }
  
  /* Form Styles */
  form {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  form input[type="file"] {
    padding: 10px 15px;
    border-radius: 20px;
    border: 2px solid #ddd;
    width: 250px;
    font-size: 1rem;
  }
  
  .Prompt-App form button {
    padding: 10px 20px;
    background-color: #007BFF; /* Matching button color */
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-size: 1rem;
  }
  
  .Prompt-App form button:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
  }
  
  .Prompt-App form button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  /* Loader Styles */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Image Display */
  .prompt-generated-image {
    max-width: 30%;
    border-radius: 10px;
    margin-top: 10px;
  }

  .response-text-container {
    margin: 10px;
  }
  
  .image-upload-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-upload-form input[type="file"] {
    font-size: 18px; /* Increase font size as needed */
    cursor: pointer; /* Optional: Makes it clear it's clickable */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* For better accessibility and consistency, you might also want to adjust the button */
  .image-upload-form button {
    font-size: 18px; /* Ensure button font size matches the input */
    cursor: pointer;
  }

  .images-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Adjust the space between images */
    margin-top: 20px; /* Space above the images container */
  }
  
  .image-wrapper {
    text-align: center; /* Center the image label */
  }
  
  .uploaded-image, .prompt-generated-image {
    max-width: 400px; /* Adjust based on preference and available space */
    border-radius: 5px; /* Optional: for styled corners */
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .Prompt-App-header {
      border-radius: 10px; /* Less pronounced border-radius on smaller screens */
      padding: 5px; /* Smaller padding */
    }
    form input[type="text"], form button {
      width: auto; /* Allow form elements to fill available space */
      max-width: 250px; /* Prevent them from becoming too wide */
      font-size: 0.9rem; /* Slightly smaller font size */
    }
  }
  
  @media (max-width: 480px) {
    .Prompt-App {
      padding: 10px; /* Reduce the padding on smaller screens */
    }
    .Prompt-App-header {
      border-radius: 20px; /* Adjust if needed */
      padding: 1px; /* You can adjust this if needed for the content inside the white box */
    }
    form input[type="text"], form button {
      width: auto; /* Allow form elements to fill available space */
      max-width: 150px; /* Prevent them from becoming too wide */
      font-size: 1rem; /* Slightly smaller font size */
    }
    .image-upload-form {
        flex-direction: column;
      }  
    .image-upload-form input,
    .image-upload-form button {
        width: 100%; /* Make input and button take the full width */
        margin: 5px 0; /* Add some margin for spacing */
    }
    .images-container {
      flex-direction: column;
    }
    .uploaded-image, .prompt-generated-image {
      max-width: 50%; /* Larger images on smaller screens */
      margin-bottom: 10px; /* Space between stacked images */
    }
  }
  .social-links {
    display: flex;
    justify-content: center;
    gap: 20px; /* Adjust the space between icons */
    margin-bottom: 10px;
  }
  
  .social-links a {
    color: #000; /* Icon color */
    text-decoration: none; /* Removes underline from links */
    display: flex;
    align-items: center;
    gap: 10px; /* Space between the icon and text */
  }
    
  .social-links i {
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .social-links a:hover i {
    color: #007bff; /* Change color on hover */
  }

  .version-number {
    font-size: 0.5em; /* Adjust based on your design */
    vertical-align: super; /* Raises the text, you can adjust or use 'top', 'middle', etc. */
    margin-left: 10px; /* Adds a little space between the header and version number */
  }
  
  
  