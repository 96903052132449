.collapsible-text .controls {
    display: flex;
    justify-content: center; /* Center buttons horizontally */
    gap: 10px; /* Add some space between buttons */
    margin-top: 10px; /* Space above the buttons */
  }
  
  .collapsible-text .toggle-button, .collapsible-text .copy-button {
    background: none;
    border: 1px solid #007bff;
    color: #007bff;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .collapsible-text .toggle-button:hover, .collapsible-text .copy-button:hover {
    background: #007bff;
    color: white;
  }
  